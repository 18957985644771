import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DropdownButton from 'react-bootstrap/DropdownButton'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'

import ProgesteroneOptions from '../components/atoms/dropdowns/rhythmic-dosing-chart-dropdowns/progesterone-options'
import EstradiolOptions from '../components/atoms/dropdowns/rhythmic-dosing-chart-dropdowns/estradiol-options'
import MenstrualCycleChart from '../components/molecules/charts/menstrual-cycle-chart'
import RythmicDosingChart from '../components/molecules/charts/rythmic-dosing-chart'

import './physiologic-restoration.scss'


/* ------------------------------------------------------------
Define unicode subscript characters for use with plot labels.
------------------------------------------------------------ */
const subScriptTwo = '\u2082'
const subScriptFour = '\u2084'
// const oneHalf = '½'

/* ------------------------------------------------------------
Initial values to plot when page loads (array of zeros of length 28).
------------------------------------------------------------ */
const estradiolLabel = 'E'+subScriptTwo
const baselineEstradiolTitle = ''
const baselineEstradiolColor = 'rgba(255, 0, 0, .3)'
const baselineEstradiolData = []
for(var j = 0; j < 28; j++) {
  baselineEstradiolData.push(0);
}

const progesteroneLabel = 'P'+subScriptFour
const baselineProgesteroneTitle = ''
const baselineProgesteroneColor = 'rgba(238, 130, 238, .5)'
const baeselineProgesteroneData = []
for(var k = 0; k < 28; k++) {
  baeselineProgesteroneData.push(0);
}


const UnderstandYourProtocolPage = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      estradiolData: baselineEstradiolData,
      estradiolLabel: estradiolLabel,
      estradiolColor: baselineEstradiolColor,
      estradiolTitle: baselineEstradiolTitle,
      progesteroneData: baeselineProgesteroneData,
      progesteroneLabel: progesteroneLabel,
      progesteroneColor: baselineProgesteroneColor,
      progesteroneTitle: baselineProgesteroneTitle,
      options: this.protocolOptions
    }

    this.estradiolHandler = this.estradiolHandler.bind(this)
    this.progesteroneHandler = this.progesteroneHandler.bind(this)
  }

  estradiolHandler(data, color, title) {
    this.setState({
      estradiolData: data,
      estradiolColor: color,
      estradiolTitle: title
    })
  }

  progesteroneHandler(data, title) {
    this.setState({
      progesteroneData: data,
      progesteroneTitle: title
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="G Jones for Hormones" />
        <Container>
          <Row style={{ marginTop: '3rem', marginBottom: '3rem' }}>
            <Col>
              <h1>Physiologic Restoration</h1>
            </Col>
          </Row>
        </Container>
        <Container fluid className="d-lg-none">
          <Row className="justify-content-center" style={{ marginBottom: '1rem' }}>
            <Col>
              <MenstrualCycleChart
                title='Hormone Changes in An Average Cycle'
              />
            </Col>
          </Row>
        </Container>
        <Container className="d-none d-lg-block">
          <Row className="justify-content-center" style={{ marginBottom: '1rem' }}>
            <Col lg={11}>
              <MenstrualCycleChart
                title='Hormone Changes in An Average Cycle'
              />
            </Col>
          </Row>
        </Container>
        <Container fluid className="d-lg-none">
          <Row className="justify-content-center" style={{ marginBottom: '1rem' }}>
            <Col>
              <RythmicDosingChart
                title='Rhythmic Dosing Estradiol / Progesterone Monthly Cycle - Total Daily Dose'
                data={this.state}
              />
            </Col>
          </Row>
        </Container>
        <Container className="d-none d-lg-block">
          <Row className="justify-content-center" style={{ marginBottom: '1rem' }}>
            <Col lg={11}>
              <RythmicDosingChart
                title='Rhythmic Dosing Estradiol / Progesterone Monthly Cycle - Total Daily Dose'
                data={this.state}
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center" style={{ marginBottom: '3rem' }}>
            <Col md={4}>
              <div className="d-flex justify-content-center mb-3 mb-md-0">
                <DropdownButton
                  id="estradiol-dropdown-button"
                  title={`Estradiol:\xa0\xa0` + this.state.estradiolTitle + `\xa0\xa0`}
                  className="mr-md-5"
                >
                  <EstradiolOptions
                    estradiolHandler={this.estradiolHandler}
                  />
                </DropdownButton>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-flex justify-content-center">
                <DropdownButton
                  id="progesterone-dropdown-button"
                  title={`Progesterone:\xa0\xa0` + this.state.progesteroneTitle + `\xa0\xa0`}
                  className="mr-md-5"
                >
                  <ProgesteroneOptions
                    progesteroneHandler={this.progesteroneHandler}
                  />
                </DropdownButton>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: '3rem' }}>
            <Col>
                <p>
                  This graph is a visual for you to see your hormone cycle in phases instead of days. The follicular phase is the time when you are on baseline estradiol only, and the time when you may be having a menstrual bleed days 1-5, the new follicles are developing into a dominant follicle that will lead to the ovulatory phase. The ovulatory phase is when the estrogen peaks (due to the dominant follicle) and this then begins the luteal phase when you are now using both estrogen and progesterone. The Cycle day are guideposts so that you know where you are in each phase of the cycle. This graph is representing a 28 day cycle.
                </p>
                <p>
                  Your practitioner may adjust your dose to get your blood levels optimal. Estradiol should cycle between 150-500 pg/ml and progesterone between 1-19 ng/ml in the 28 day cycle. These changes can be made per your prescriber based on physical symptoms and response to the hormones and by monitoring your lab results.
                </p>
            </Col>
          </Row>
        </Container>
        <ScheduleConsultation />
      </Layout>
    )
  }
}

export default UnderstandYourProtocolPage
