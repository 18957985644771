import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import Dropdown from 'react-bootstrap/Dropdown'

import '../drop-down.scss'


export default function EstradiolOptions(props) {
  return (
    <StaticQuery
      query={graphql`
        query estradiolQuery {
          allFile(filter: {name: {eq: "estradiol"}}) {
            edges {
              node {
                childDataJson {
                  estradiol0 {
                    color
                    data
                    title
                  }
                  estradiol2 {
                    color
                    data
                    title
                  }
                  estradiol4 {
                    color
                    data
                    title
                  }
                  estradiol6 {
                    color
                    data
                    title
                  }
                  estradiol8 {
                    color
                    data
                    title
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {
            Object.values(data.allFile.edges[0].node.childDataJson).map((data, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    props.estradiolHandler(
                      data.data.map(function(n) {return n*2}),
                      data.color,
                      data.title
                    )
                  }}
                >
                  {data.title}
                </Dropdown.Item>
              )
            })
          }
        </>
      )}
    />
  )
}
