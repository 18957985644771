import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Dropdown from 'react-bootstrap/Dropdown'

import '../drop-down.scss'


export default function ProgesteroneOptions(props) {
  return (
    <StaticQuery
      query={graphql`
        query progesteroneQuery {
          allFile(filter: {name: {eq: "progesterone"}}) {
            edges {
              node {
                childDataJson {
                  progesterone1 {
                    data
                    title
                  }
                  progesterone2 {
                    data
                    title
                  }
                  progesterone3 {
                    data
                    title
                  }
                  progesterone4 {
                    data
                    title
                  }
                  # progesterone5 {
                  #   data
                  #   title
                  # }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          {
            Object.values(data.allFile.edges[0].node.childDataJson).map((data, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    props.progesteroneHandler(
                      data.data.map(function(n) {return n*2}),
                      data.title
                    )
                  }}
                >
                  {data.title}
                </Dropdown.Item>
              )
            })
          }
        </>
      )}
    />
  )
}
