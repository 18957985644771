import React, { Component } from 'react'

import { Chart, Line } from 'react-chartjs-2'
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation'


export default class RythmicDosingChart extends Component {
  componentWillMount() {
    Chart.pluginService.register(ChartAnnotationsPlugin)
  }

  render() {
    let protocolChart = {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
      datasets: [
        {
          data: this.props.data.estradiolData,
          label: this.props.data.estradiolLabel,
          fill: false,
          borderColor: this.props.data.estradiolColor,
          backgroundColor: this.props.data.estradiolColor,
          pointBorderColor: this.props.data.estradiolColor,
          pointBackgroundColor: this.props.data.estradiolColor,
          borderWidth: 4
        },
        {
          data: this.props.data.progesteroneData,
          label: this.props.data.progesteroneLabel,
          fill: false,
          borderColor: this.props.data.progesteroneColor,
          backgroundColor: this.props.data.progesteroneColor,
          pointBorderColor: this.props.data.progesteroneColor,
          pointBackgroundColor: this.props.data.progesteroneColor,
          borderWidth: 4
        }
      ]
    }

    const protocolOptions = {
      maintainAspectRatio : false,
      title: {
        display: true,
        text: this.props.title
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'mL'
          },
          ticks: {
            stepSize: 2,
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 30
          }
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'day'
          }
        }]
      },
      elements: {
        line: {
          tension: .4
        }
      }
    }

    const desktopAnnotations = {
      annotation: {
        annotations: [
          {
            id: 'luteal-begin',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '15',
            borderColor: 'rgba(0, 0, 255, 0.5)',
            borderWidth: 3,
            borderDash: [2, 5],
            label: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
              fontFamily: "sans-serif",
              fontSize: 14,
              fontStyle: "bold",
              fontColor: "rgba(0, 0, 255, 0.5)",
              position: "top",
              xAdjust: -230,
              yAdjust: 0,
              enabled: true,
              content: "Luteal Phase"
            },
          },
          {
            // drawTime: 'afterDraw', // overrides annotation.drawTime if set
            id: 'follicular-end', // optional
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '13',
            borderColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 3,
            borderDash: [2, 5],
            label: {
              // Background color of label, default below
              backgroundColor: 'rgba(0, 0, 0, 0)',
              // Font family of text, inherits from global
              fontFamily: "sans-serif",
              // Font size of text, inherits from global
              fontSize: 14,
              // Font style of text, default below
              fontStyle: "bold",
              // Font color of text, default below
              fontColor: "rgba(238, 130, 238, .75)",
              // Padding of label to add left/right, default below
              // xPadding: 6,
              // Padding of label to add top/bottom, default below
              // yPadding: 6,
              // Radius of label rectangle, default below
              // cornerRadius: 2,
              // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
              position: "top",
              // Adjustment along x-axis (left-right) of label relative to above number (can be negative)
              // For horizontal lines positioned left or right, negative values move
              // the label toward the edge, and positive values toward the center.
              xAdjust: -35,
              // Adjustment along y-axis (top-bottom) of label relative to above number (can be negative)
              // For vertical lines positioned top or bottom, negative values move
              // the label toward the edge, and positive values toward the center.
              yAdjust: 0,
              // Whether the label is enabled and should be displayed
              enabled: true,
              // Text to display in label - default is null. Provide an array to display values on a new line
              content: "Ovulation"
            },
            // Fires when the user clicks this annotation on the chart
            // (be sure to enable the event in the events array below).
            // onClick: function(e) {
              // `this` is bound to the annotation element
            // }
          },
          {
            id: 'menstruation-end',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '5',
            borderColor: 'rgba(0, 0, 0, 0.25)',
            borderWidth: 3,
            borderDash: [2, 5],
            label: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
              fontFamily: "sans-serif",
              fontSize: 14,
              fontStyle: "bold",
              fontColor: "rgba(0, 0, 0, .25)",
              position: "top",
              xAdjust: 73,
              yAdjust: 0,
              enabled: true,
              content: "Menstruation"
            },
          },
          {
            id: 'follicular-continued',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '5',
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 3,
            borderDash: [2, 5],
            label: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
              fontFamily: "sans-serif",
              fontSize: 14,
              fontStyle: "bold",
              fontColor: "rgba(255, 0, 0, 0.5)",
              position: "top",
              xAdjust: -142,
              yAdjust: 0,
              enabled: true,
              content: "Follicular Phase"
            },
          },
          {
            id: 'follicular-start',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '1',
            borderColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 6,
            borderDash: [2, 5]
          },
          {
            id: 'luteal-end',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: '28',
            borderColor: 'rgba(0, 0, 255, 0.5)',
            borderWidth: 6,
            borderDash: [2, 5]
          }
        ]
      }
    }

    return (
      <>
        <div
          className="d-md-none"
          style={{ height: '482px' }}
        >
          <Line
            data={protocolChart}
            options={{
              ...protocolOptions
            }}
          />
        </div>
        <div
          className="d-none d-md-block"
          style={{ height: '482px' }}
        >
          <Line
            data={protocolChart}
            options={{
              ...protocolOptions,
              ...desktopAnnotations
            }}
          />
        </div>
      </>
    )
  }
}
